import { NgModule } from '@angular/core';
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LazyLoadImageDirective, LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { CabecalhosComponent } from './pages/cabecalhos/cabecalhos.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';
import { CategoriasCriarComponent } from './pages/categorias-criar/categorias-criar.component';
import { CategoriasEditarComponent } from './pages/categorias-editar/categorias-editar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { PortifoliosComponent } from './pages/portifolios/portifolios.component';
import { PortifoliosCriarComponent } from './pages/portifolios-criar/portifolios-criar.component';
import { PortifoliosEditarComponent } from './pages/portifolios-editar/portifolios-editar.component';
import { FilterPipe } from './pipes/filter.pipe';
import { MensagensComponent } from './pages/mensagens/mensagens.component';
import { EnsaiosComponent } from './pages/ensaios/ensaios.component';
import { EnsaiosCriarComponent } from './pages/ensaios-criar/ensaios-criar.component';
import { EnsaiosEditarComponent } from './pages/ensaios-editar/ensaios-editar.component';
import { EnsaiosClienteComponent } from './pages/ensaios-cliente/ensaios-cliente.component';
import { EnsaiosClienteEditarComponent } from './pages/ensaios-cliente-editar/ensaios-cliente-editar.component';
import { ModalImageComponent } from './components/modal-image/modal-image.component';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pages
    HomeComponent,
    LoginComponent,
    ConfiguracoesComponent,
    ContatoComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    BannersComponent,
    BannersCriarComponent,
    BannersEditarComponent,
    CabecalhosComponent,
    CategoriasComponent,
    CategoriasCriarComponent,
    CategoriasEditarComponent,
    NoticiasComponent,
    NoticiasCriarComponent,
    NoticiasEditarComponent,
    SobreComponent,
    PortifoliosComponent,
    PortifoliosCriarComponent,
    PortifoliosEditarComponent,
    FilterPipe,
    MensagensComponent,
    EnsaiosComponent,
    EnsaiosCriarComponent,
    EnsaiosEditarComponent,
    EnsaiosClienteComponent,
    EnsaiosClienteEditarComponent,
    ModalImageComponent,
    // LazyLoadImageDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxSummernoteModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMatSelectSearchModule,
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    NgxDropzoneModule,
    DragDropModule,
    MatChipsModule,
    LazyLoadImageModule
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent,
    ModalImageComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
