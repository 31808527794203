import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';

@Injectable({
  providedIn: 'root'
})
export class ContatoMensagemService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<MensagensApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/contatoMensagem/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/contatoMensagem/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/contatoMensagem/buscar?id=${id}`);
  }

  post(mensagem: ContatoMensagem): Observable<any> {
    return this.api.post('/contatoMensagem/inserir', mensagem);
  }

  patch(mensagem: ContatoMensagem): Observable<any> {
    return this.api.post('/contatoMensagem/alterar', mensagem);
  }

  delete(mensagem: ContatoMensagem): Observable<any> {
    return this.api.post('/contatoMensagem/deletar', mensagem);
  }

  deleteSelected(mensagens: ContatoMensagem[]): Observable<any> {
    return this.api.post('/contatoMensagem/deletarLista', mensagens);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  excel() {
    return this.api.get(`/contatoMensagem/excel`);
  }
}

export interface MensagensApi {
  mensagens: ContatoMensagem[];
  numeroPaginas: number;
}