import { Portifolio } from 'src/app/models/portifolio.model';
import { PortifolioService } from 'src/app/services/class/portifolio.service';
import { Categoria } from 'src/app/models/categoria.model';
import { CategoriaService, CategoriasApi } from 'src/app/services/class/categoria.service';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-portifolios-criar',
  templateUrl: './portifolios-criar.component.html',
  styleUrls: ['./portifolios-criar.component.scss']
})
export class PortifoliosCriarComponent implements OnInit {

  data: Portifolio = new Portifolio();

  fileImagemPequena: File;
  fileImagemGrande: File;
  progress: any;

  files: File[] = [];
  categorias: Categoria[] = [];
  buscarCategoriasSubscription: Subscription;
  searchCategorias: string = '';

  constructor(
    public helper: HelperService,
    public portifolioService: PortifolioService,
    public categoriaService: CategoriaService,
    public loadingService: LoadingService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.buscarCategorias();
  }

  ngOnDestroy() {
    this.buscarCategoriasSubscription.unsubscribe();
  }

  buscarCategorias() {
    this.buscarCategoriasSubscription = this.categoriaService.get(-99, -99, '1', 'ASC', this.data.tipo)
      .subscribe((res: CategoriasApi) => this.categorias = res.categorias, e => console.log(e));
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo...');
    this.data.slug = this.helper.convertToSlug(this.data.titulo);

    this.portifolioService.post(this.data)
      .subscribe(res => {
        this.submitImages(this.fileImagemPequena, `/portifolio/imagemPequena?id=${res}`);
        this.submitImages(this.fileImagemGrande, `/portifolio/imagemGrande?id=${res}`);
        this.router.navigate(['/portfolios/editar', res]).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        });
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  onTipoChange(event) {
    this.buscarCategorias();
  }

  @ViewChild('fileImagemPequenaInput', { static: true }) fileImagemPequenaInput: ElementRef;
  onFileImagemPequenaSelected(files) {
    this.fileImagemPequena = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPequena, '/portifolio/imagemPequena?id=-99').then((res: any) => {
      this.data.imagemPequena = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPequenaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemGrandeInput', { static: true }) fileImagemGrandeInput: ElementRef;
  onFileImagemGrandeSelected(files) {
    this.fileImagemGrande = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemGrande, '/portifolio/imagemGrande?id=-99').then((res: any) => {
      this.data.imagemGrande = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemGrandeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.portifolioService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImagemPequena() {
    this.data.imagemPequena = '';
  }

  removeImagemGrande() {
    this.data.imagemGrande = '';
  }

}
