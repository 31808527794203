export class Cabecalho {

    id: number;
    imagemSobre: string = '';
    imagemNoticia: string = '';
    imagemContato: string = '';
    imagemPortifolioVoce: string = '';
    imagemPortifolioEmpresa: string = '';
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}