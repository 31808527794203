import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pages = [
    { name: 'Ensaios', icon: 'fas fa-camera', route: '/ensaios', params: null },
    { name: 'Banners', icon: 'fas fa-images', route: '/banners', params: null },
    { name: 'Sobre', icon: 'fas fa-info-circle', route: '/sobre', params: null },
    { name: 'Cabeçalhos', icon: 'fas fa-image', route: '/cabecalhos', params: null },
    { name: 'Categorias', icon: 'fas fa-shapes', route: '/categorias', params: 'tipo: "E"' },
    { name: 'Portfolios', icon: 'fas fa-suitcase', route: '/portfolios', params: 'tipo: "E"' },
    { name: 'Notícias', icon: 'fas fa-newspaper', route: '/noticias', params: 'tipo: "E"' },
    { name: 'Usuários', icon: 'fas fa-users', route: '/usuarios', params: null },
    { name: 'Contato', icon: 'fas fa-phone-alt', route: '/contato', params: null },
    { name: 'Configurações', icon: 'fas fa-cog', route: '/configuracoes', params: null },
  ];

  constructor(
    public auth: AuthService,
    public router: Router,
    public global: GlobalService
  ) { }

  ngOnInit() {
    if (this.auth.user.tipo == 'C') {
      this.goTo('/c/ensaios');
    }
  }

  goTo(page: string, queryParams?: any) {
    this.router.navigate([page], {
      queryParams: queryParams ? queryParams : {}
    }).then(() => {
      this.global.menuOpen = false;
    });
  }

}
