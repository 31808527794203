import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(
    private api: ApiService
  ) { }

  buscarGeral() {
    return this.api.get('/contatoGeral/buscar');
  }

  alterarGeral(contatoGeral: any) {
    return this.api.post('/contatoGeral/alterar', contatoGeral);
  }
}
