import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnsaioDocumento } from 'src/app/models/ensaio-documento.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class EnsaioDocumentoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, idEnsaio: number) {
    return this.api.get(`/ensaioDocumento/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`)
  }

  getCliente(numeroPagina: number, registroPorPagina: number, idEnsaio: number) {
    return this.api.get(`/ensaioDocumento/buscar/cliente?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`)
  }

  getById(id: number) {
    return this.api.get(`/ensaioDocumento/buscar?id=${id}`);
  }

  setComment(id: number, comentario: string) {
    return this.api.get(`/ensaioDocumento/alterarComentario?id=${id}&comentario=${comentario}`);
  }

  delete(ensaioDocumento: EnsaioDocumento): Observable<any> {
    return this.api.post('/ensaioDocumento/deletar', ensaioDocumento);
  }

  deleteSelected(ensaiosDocumento: EnsaioDocumento[]): Observable<any> {
    return this.api.post('/ensaioDocumento/deletarLista', ensaiosDocumento);
  }
}
