import { Banner } from './../../models/banner.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<BannersApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/banner/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/banner/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/banner/buscar?id=${id}`);
  }

  post(banner: Banner): Observable<any> {
    return this.api.post('/banner/inserir', banner);
  }

  patch(banner: Banner): Observable<any> {
    return this.api.post('/banner/alterar', banner);
  }

  delete(banner: Banner): Observable<any> {
    return this.api.post('/banner/deletar', banner);
  }

  deleteSelected(banners: Banner[]): Observable<any> {
    return this.api.post('/banner/deletarLista', banners);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface BannersApi {
  banners: Banner[];
  numeroPaginas: number;
}
