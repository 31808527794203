import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { CabecalhosComponent } from './pages/cabecalhos/cabecalhos.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';
import { CategoriasCriarComponent } from './pages/categorias-criar/categorias-criar.component';
import { CategoriasEditarComponent } from './pages/categorias-editar/categorias-editar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { PortifoliosComponent } from './pages/portifolios/portifolios.component';
import { PortifoliosCriarComponent } from './pages/portifolios-criar/portifolios-criar.component';
import { PortifoliosEditarComponent } from './pages/portifolios-editar/portifolios-editar.component';
import { MensagensComponent } from './pages/mensagens/mensagens.component';
import { EnsaiosComponent } from './pages/ensaios/ensaios.component';
import { EnsaiosCriarComponent } from './pages/ensaios-criar/ensaios-criar.component';
import { EnsaiosEditarComponent } from './pages/ensaios-editar/ensaios-editar.component';
import { EnsaiosClienteComponent } from './pages/ensaios-cliente/ensaios-cliente.component';
import { EnsaiosClienteEditarComponent } from './pages/ensaios-cliente-editar/ensaios-cliente-editar.component';
import { IsAdminLoggedInGuard } from './guards/is-admin-logged-in.guard';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners', component: BannersComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/adicionar', component: BannersCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/editar/:id', component: BannersEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'cabecalhos', component: CabecalhosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias', component: CategoriasComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias/adicionar', component: CategoriasCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias/editar/:id', component: CategoriasEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias', component: NoticiasComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias/adicionar', component: NoticiasCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias/editar/:id', component: NoticiasEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'sobre', component: SobreComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'mensagens', component: MensagensComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'portfolios', component: PortifoliosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'portfolios/adicionar', component: PortifoliosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'portfolios/editar/:id', component: PortifoliosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'ensaios', component: EnsaiosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'ensaios/adicionar', component: EnsaiosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'ensaios/editar/:id', component: EnsaiosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'c/ensaios', component: EnsaiosClienteComponent, canActivate: [IsLoggedInGuard] },
  { path: 'c/ensaios/:id', component: EnsaiosClienteEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
