import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  pages = [
    { name: 'Home', icon: 'fas fa-home', route: '/' },
    { name: 'Ensaios', icon: 'fas fa-camera', route: '/ensaios' },
    { name: 'Banners', icon: 'fas fa-images', route: '/banners' },
    { name: 'Sobre', icon: 'fas fa-info-circle', route: '/sobre' },
    { name: 'Cabeçalhos', icon: 'fas fa-image', route: '/cabecalhos' },
    { name: 'Categorias', icon: 'fas fa-shapes', route: '/categorias', params: 'tipo: "E"' },
    { name: 'Portfolios', icon: 'fas fa-suitcase', route: '/portfolios', params: 'tipo: "E"' },
    { name: 'Notícias', icon: 'fas fa-newspaper', route: '/noticias', params: 'tipo: "E"' },
    { name: 'Usuários', icon: 'fas fa-users', route: '/usuarios' },
    { name: 'Contato', icon: 'fas fa-phone-alt', route: '/contato' },
    { name: 'Configurações', icon: 'fas fa-cog', route: '/configuracoes' },
  ];

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  goTo(page: string, queryParams?: any) {
    this.router.navigate([page], {
      queryParams: queryParams ? queryParams : {}
    }).then(() => {
      this.global.menuOpen = false;
    });
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    })
  }

}
