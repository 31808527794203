import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnsaioFotoTratada } from 'src/app/models/ensaio-foto-tratada.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class EnsaioFotoTratadaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, idEnsaio: number): Observable<EnsaioFotoTratada[]> {
    let requestUrl: string;
    return this.api.get(`/ensaioFotoTratada/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/ensaioFotoTratada/buscar?id=${id}`);
  }

  post(foto: EnsaioFotoTratada): Observable<any> {
    return this.api.post('/ensaioFotoTratada/inserir', foto);
  }

  delete(foto: EnsaioFotoTratada): Observable<any> {
    return this.api.post('/ensaioFotoTratada/deletar', foto);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  multiple(idEnsaio: number) {
    return this.api.post(`/ensaioFotoTratada/inserirMultiplo?idEnsaio=${idEnsaio}`);
  }

  download(idEnsaio: number) {
    return this.api.get(`/ensaioFotoTratada/baixarZip?idEnsaio=${idEnsaio}`);
  }
}
