import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnsaioFoto } from 'src/app/models/ensaio-foto.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class EnsaioFotoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, idEnsaio: number): Observable<EnsaioFoto[]> {
    let requestUrl: string;
    return this.api.get(`/ensaioFoto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/ensaioFoto/buscar?id=${id}`);
  }

  getSelect(numeroPagina: number, registroPorPagina: number, idEnsaio: number): Observable<EnsaioFoto[]> {
    return this.api.get(`/ensaioFoto/buscar/cliente?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`);
  }

  post(foto: EnsaioFoto): Observable<any> {
    return this.api.post('/ensaioFoto/inserir', foto);
  }

  patch(foto: EnsaioFoto): Observable<any> {
    return this.api.post('/ensaioFoto/alterar', foto);
  }

  delete(foto: EnsaioFoto): Observable<any> {
    return this.api.post('/ensaioFoto/deletar', foto);
  }

  deleteSelected(fotos: EnsaioFoto[]): Observable<any> {
    return this.api.post('/ensaioFoto/deletarLista', fotos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  multiple(idEnsaio: number) {
    return this.api.post(`/ensaioFoto/inserirMultiplo?idEnsaio=${idEnsaio}`);
  }

  changeFavorite(id: number, favorita: string) {
    return this.api.get(`/ensaioFoto/alterarFavorito?id=${id}&favorita=${favorita}`)
  }

  changeComment(id: number, comentario: string) {
    return this.api.get(`/ensaioFoto/alterarComentario?id=${id}&comentario=${comentario}`);
  }
}
