import { Observable } from 'rxjs';
import { Usuario } from './../../models/usuario.model';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, tipo: string = 'N', filtro?: string): Observable<UsuariosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/usuario/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&tipo=${tipo}`;
    } else {
      requestUrl = `/usuario/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&tipo=${tipo}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/usuario/buscar?id=${id}`);
  }

  getByEmail(email): Observable<any> {
    return this.api.get(`/usuario/buscar?email=${email}`);
  }

  getSelect(numeroPagina: number, registroPorPagina: number, filtro: string = '%') {
    let requestUrl = `/usuario/buscar/cliente?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`;
    return this.api.get(requestUrl);
  }

  post(user: Usuario): Observable<any> {
    return this.api.post('/usuario/inserir', user);
  }

  patch(user: Usuario): Observable<any> {
    return this.api.post('/usuario/alterar', user);
  }

  delete(user: Usuario): Observable<any> {
    return this.api.post('/usuario/deletar', user);
  }

  deleteSelected(users: Usuario[]): Observable<any> {
    return this.api.post('/usuario/deletarLista', users);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Usuario, v2: Usuario): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface UsuariosApi {
  usuarios: Usuario[];
  numeroPaginas: number;
}
