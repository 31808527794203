import { Noticia } from './../../models/noticia.model';
import { NoticiaService } from 'src/app/services/class/noticia.service';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-noticias-criar',
  templateUrl: './noticias-criar.component.html',
  styleUrls: ['./noticias-criar.component.scss']
})
export class NoticiasCriarComponent implements OnInit {

  data: Noticia = new Noticia();

  fileImagemPequena: File;
  fileImagemGrande: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public noticiaService: NoticiaService,
    public loadingService: LoadingService,
    public router: Router
  ) {
  }

  ngOnInit() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo...');
    this.data.slug = this.helper.convertToSlug(this.data.titulo);

    this.noticiaService.post(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.fileImagemPequena, `/noticia/imagemPequena?id=${res}`);
        this.submitImages(this.fileImagemGrande, `/noticia/imagemGrande?id=${res}`);
        this.router.navigate(['/noticias'], {
          queryParams: { tipo: this.data.tipo }
        }).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  @ViewChild('fileImagemPequenaInput', { static: true }) fileImagemPequenaInput: ElementRef;
  onFileImagemPequenaSelected(files) {
    this.fileImagemPequena = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPequena, '/noticia/imagemPequena?id=-99').then((res: any) => {
      this.data.imagemPequena = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPequenaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemGrandeInput', { static: true }) fileImagemGrandeInput: ElementRef;
  onFileImagemGrandeSelected(files) {
    this.fileImagemGrande = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemGrande, '/noticia/imagemGrande?id=-99').then((res: any) => {
      this.data.imagemGrande = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemGrandeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.noticiaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImagemPequena() {
    this.data.imagemPequena = '';
  }

  removeImagemGrande() {
    this.data.imagemGrande = '';
  }

}
