import { AuthService } from './../../services/auth.service';
import { NgForm } from '@angular/forms';
import { HelperService } from './../../services/helper.service';
import { Subscription } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { UsuarioService } from './../../services/class/usuario.service';
import { Usuario } from './../../models/usuario.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-usuarios-editar',
  templateUrl: './usuarios-editar.component.html',
  styleUrls: ['./usuarios-editar.component.scss']
})
export class UsuariosEditarComponent implements OnInit {

  data: Usuario = new Usuario();
  loading: boolean = false;
  hide: boolean = true;

  buscarSubscription: Subscription;

  constructor(
    public usuarioService: UsuarioService,
    public route: ActivatedRoute,
    public router: Router,
    public loadingService: LoadingService,
    public helper: HelperService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarUsuario(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscarUsuario(id: number) {
    this.loading = true;
    this.buscarSubscription = this.usuarioService.getById(id)
      .subscribe((res: Usuario) => {
        this.data = res;
        this.loading = false;
      }, e => this.loading = false)
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (this.data)

      this.loadingService.present('Alterando...');

    this.usuarioService.patch(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/usuarios'], {
          queryParams: { tipo: this.data.tipo }
        }).then(() => {
          if (this.auth.user.id == this.data.id) {
            this.auth.user = this.data;
            this.auth.setUser(this.data);
          }
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => this.loadingService.dismiss());

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  onTipoChange(e) {
  }

}
