import { HelperService } from './../../services/helper.service';
import { Subscription } from 'rxjs';
import { HttpProgressEvent } from '@angular/common/http';
import { CabecalhoService } from './../../services/class/cabecalho.service';
import { LoadingService } from './../../services/loading.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Cabecalho } from 'src/app/models/cabecalho.model';

@Component({
  selector: 'app-cabecalhos',
  templateUrl: './cabecalhos.component.html',
  styleUrls: ['./cabecalhos.component.scss']
})
export class CabecalhosComponent implements OnInit {

  cabecalho: Cabecalho = new Cabecalho();

  buscarCabecalhoSubscription: Subscription;

  fileImagemSobre: File;
  fileImagemNoticia: File;
  fileImagemContato: File;
  fileImagemPortifolioVoce: File;
  fileImagemPortifolioEmpresa: File;

  progress: number = 0;

  constructor(
    public loadingService: LoadingService,
    public cabecalhoService: CabecalhoService,
    public helper: HelperService
  ) { }

  ngOnInit() {
    this.buscarCabecalhoSubscription = this.cabecalhoService.get()
      .subscribe((res: Cabecalho) => this.cabecalho = res);
  }

  ngOnDestroy() {
    this.buscarCabecalhoSubscription.unsubscribe();
  }

  alterarCabecalhos() {
    this.loadingService.present('Alterando sobre...');

    this.cabecalhoService.patch(this.cabecalho)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Cabeçalhos alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }

  // sobre
  @ViewChild('fileImagemSobreInput', { static: true }) fileImagemSobreInput: ElementRef;
  onFileImagemSobreSelected(files) {
    this.fileImagemSobre = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemSobre, '/cabecalho/imagemSobre').then((res: any) => {
      this.cabecalho.imagemSobre = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemSobreInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  // noticias
  @ViewChild('fileImagemNoticiaInput', { static: true }) fileImagemNoticiaInput: ElementRef;
  onFileImagemNoticiaSelected(files) {
    this.fileImagemNoticia = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemNoticia, '/cabecalho/imagemNoticia').then((res: any) => {
      this.cabecalho.imagemNoticia = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemNoticiaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  // contato
  @ViewChild('fileImagemContatoInput', { static: true }) fileImagemContatoInput: ElementRef;
  onFileImagemContatoSelected(files) {
    this.fileImagemContato = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemContato, '/cabecalho/imagemContato').then((res: any) => {
      this.cabecalho.imagemContato = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemContatoInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  // portfólio você
  @ViewChild('fileImagemPortifolioVoceInput', { static: true }) fileImagemPortifolioVoceInput: ElementRef;
  onFileImagemPortifolioVoceSelected(files) {
    this.fileImagemPortifolioVoce = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPortifolioVoce, '/cabecalho/imagemPortifolioVoce').then((res: any) => {
      this.cabecalho.imagemPortifolioVoce = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPortifolioVoceInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  // portfólio empresa
  @ViewChild('fileImagemPortifolioEmpresaInput', { static: true }) fileImagemPortifolioEmpresaInput: ElementRef;
  onFileImagemPortifolioEmpresaSelected(files) {
    this.fileImagemPortifolioEmpresa = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPortifolioEmpresa, '/cabecalho/imagemPortifolioEmpresa').then((res: any) => {
      this.cabecalho.imagemPortifolioEmpresa = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPortifolioEmpresaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.cabecalhoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImageSobre() {
    this.cabecalho.imagemSobre = '';
  }

  removeImageNoticia() {
    this.cabecalho.imagemNoticia = '';
  }

  removeImageContato() {
    this.cabecalho.imagemContato = '';
  }

  removeImagePortifolioVoce() {
    this.cabecalho.imagemPortifolioVoce = '';
  }

  removeImagePortifolioEmpresa() {
    this.cabecalho.imagemPortifolioEmpresa = '';
  }

}
