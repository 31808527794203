import { Ensaio } from "src/app/models/ensaio.model";
import { EnsaioService } from "src/app/services/class/ensaio.service";
// default
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { HttpProgressEvent } from "@angular/common/http";
import { MatDialog, MatSlideToggleChange } from "@angular/material";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { UsuarioService } from "src/app/services/class/usuario.service";
import { Subscription } from "rxjs";
import { Usuario } from "src/app/models/usuario.model";
import { EnsaioFotoService } from "src/app/services/class/ensaio-foto.service";
import { EnsaioFoto } from "src/app/models/ensaio-foto.model";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { EnsaioFotoEscolhidaService } from "src/app/services/class/ensaio-foto-escolhida.service";
import { EnsaioFotoEscolhida } from "src/app/models/ensaio-foto-escolhida.model";
import { EnsaioFotoTratada } from "src/app/models/ensaio-foto-tratada.model";
import { EnsaioFotoTratadaService } from "src/app/services/class/ensaio-foto-tratada.service";
import { EnsaioDocumento } from "src/app/models/ensaio-documento.model";
import { EnsaioDocumentoService } from "src/app/services/class/ensaio-documento.service";

@Component({
  selector: "app-ensaios-editar",
  templateUrl: "./ensaios-editar.component.html",
  styleUrls: ["./ensaios-editar.component.scss"],
})
export class EnsaiosEditarComponent implements OnInit {
  data: Ensaio = new Ensaio();
  status: string = "";

  buscarClientesSubscription: Subscription;

  searchClientes: string = "";

  clientes: Usuario[] = [];

  file: File;
  progress: any;

  files: File[] = [];
  filesTratada: File[] = [];
  filesDocumentos: File[] = [];

  page: number = 1;
  itemsPerPage: number = 15;

  fotos: EnsaioFoto[] = [];
  documentos: EnsaioDocumento[] = [];
  fotosEscolhidas: EnsaioFotoEscolhida[] = [];
  fotosTratadas: EnsaioFotoTratada[] = [];

  selectedTabIndex: number = 0;

  @HostListener("window:scroll", [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 44
    ) {
      if (this.selectedTabIndex == 2 && !this.noMoreFotos) {
        this.loadMoreFotos();
      }
      if (this.selectedTabIndex == 3 && !this.noMoreFotosEscolhidas) {
        this.loadMoreFotosEscolhidas();
      }
      if (this.selectedTabIndex == 4 && !this.noMoreFotosTratadas) {
        this.loadMoreFotosTratadas();
      }
    }
  }

  constructor(
    public helper: HelperService,
    public ensaioService: EnsaioService,
    public ensaioFotoService: EnsaioFotoService,
    public ensaioDocumentoService: EnsaioDocumentoService,
    public ensaioFotoEscolhidaService: EnsaioFotoEscolhidaService,
    public ensaioFotoTratadaService: EnsaioFotoTratadaService,
    public usuarioService: UsuarioService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.buscarClientes();
    this.route.params.subscribe((param) => {
      this.buscar(param.id);
      this.buscarFotos(param.id);
      this.buscarFotosEscolhidas(param.id);
      this.buscarFotosTratadas(param.id);
      this.buscarDocumentos(param.id);
    });
  }

  ngOnDestroy() {
    this.buscarClientesSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.ensaioService.getById(id).subscribe((res: Ensaio) => {
      this.data = res;
      this.status = res.status;
    });
  }

  buscarClientes(filtro: string = "%") {
    this.buscarClientesSubscription = this.usuarioService
      .getSelect(-99, -99, filtro)
      .subscribe((res: any) => (this.clientes = res));
  }

  pageFotos: number = 1;
  noMoreFotos: boolean = false;
  buscarFotos(idEnsaio: number) {
    this.ensaioFotoService
      .get(this.pageFotos, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFoto[]) => {
        if (this.noMoreFotos) {
          return;
        }

        if (res.length < this.itemsPerPage) {
          this.noMoreFotos = true;
        }

        this.fotos = this.pageFotos == 1 ? res : this.fotos.concat(res);

        this.fotos = this.fotos.map((foto) => {
          foto.isEditing = false;
          if (foto.favorita == "S") {
            foto.favorited = true;
          } else {
            foto.favorited = false;
          }
          return foto;
        });
      });
  }

  loadMoreFotos() {
    this.pageFotos++;
    this.buscarFotos(this.data.id);
  }

  pageFotosEscolhidas: number = 1;
  noMoreFotosEscolhidas: boolean = false;
  buscarFotosEscolhidas(idEnsaio: number) {
    this.ensaioFotoEscolhidaService
      .get(this.pageFotosEscolhidas, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFotoEscolhida[]) => {
        if (this.noMoreFotosEscolhidas) {
          return;
        }
        if (res.length < this.itemsPerPage) {
          this.noMoreFotosEscolhidas = true;
        }
        this.fotosEscolhidas =
          this.pageFotosEscolhidas == 1
            ? res
            : this.fotosEscolhidas.concat(res);
      });
  }

  loadMoreFotosEscolhidas() {
    this.pageFotosEscolhidas++;
    this.buscarFotosEscolhidas(this.data.id);
  }

  pageFotosTratadas: number = 1;
  noMoreFotosTratadas: boolean = false;
  buscarFotosTratadas(idEnsaio: number) {
    this.ensaioFotoTratadaService
      .get(this.pageFotosTratadas, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFotoTratada[]) => {
        if (this.noMoreFotosTratadas) {
          return;
        }
        if (res.length < this.itemsPerPage) {
          this.noMoreFotosTratadas = true;
        }
        this.fotosTratadas =
          this.pageFotosTratadas == 1 ? res : this.fotosTratadas.concat(res);
      });
  }

  loadMoreFotosTratadas() {
    this.pageFotosTratadas++;
    this.buscarFotosTratadas(this.data.id);
  }

  buscarDocumentos(idEnsaio: number) {
    this.ensaioDocumentoService.get(-99, -99, idEnsaio).subscribe((res) => {
      this.documentos = res;
    });
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Alterando item...");

    this.ensaioService.patch(this.data).subscribe(
      (res: any) => {
        this.submitImages(this.file, `/ensaio/imagem?id=${this.data.id}`);
        this.router.navigate(["/ensaios"]).then(() => {
          this.helper.openSnackBar("Item alterado com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present("Alterando imagem...");
    this.submitImages(this.file, "/ensaio/imagem?id=-99")
      .then((res: any) => {
        this.data.imagem = res.body;
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = "";
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.ensaioService.postFile(file, url, "image").subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        },
        (err) => reject(err)
      );
    });
  }

  removeImage() {
    this.data.imagem = "";
  }

  async onSelect(event) {
    this.files.push(...event.addedFiles);
    let count = event.addedFiles.length;

    this.loadingService.present("Inserindo");

    await event.addedFiles.reduce(async (a, file) => {
      await a;
      this.loadingService.title = `Faltam ${count} imagens...`;
      count--;
      const url = `/ensaioFoto/inserirMultiplo?idEnsaio=${this.data.id}`;
      await this.ensaioService.postFile(file, url, "arquivo").toPromise();
    }, Promise.resolve());

    this.progress = 0;
    this.files = [];
    this.noMoreFotos = false;
    this.loadingService.dismiss();
    this.buscarFotos(this.data.id);
  }

  removeDocumento(documento) {
    const index = this.documentos.findIndex((d) => d.id === documento.id);

    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.ensaioDocumentoService.delete(documento).subscribe(
          (res: any) => {
            this.documentos.splice(index, 1);
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  async onSelectDocumento(event) {
    this.files.push(...event.addedFiles);
    let count = event.addedFiles.length;

    this.loadingService.present("Inserindo");

    await event.addedFiles.reduce(async (a, file) => {
      await a;
      this.loadingService.title = `Faltam ${count} imagens...`;
      count--;
      const url = `/ensaioDocumento/inserirMultiplo?idEnsaio=${this.data.id}`;
      await this.ensaioService.postFile(file, url, "arquivo").toPromise();
    }, Promise.resolve());

    this.progress = 0;
    this.loadingService.dismiss();
    this.files = [];
    this.buscarDocumentos(this.data.id);
  }

  async onSelectTratada(event) {
    this.filesTratada.push(...event.addedFiles);
    let count = event.addedFiles.length;

    this.loadingService.present("Inserindo");

    await event.addedFiles.reduce(async (a, file) => {
      await a;
      this.loadingService.title = `Faltam ${count} imagens...`;
      count--;
      const url = `/ensaioFotoTratada/inserirMultiplo?idEnsaio=${this.data.id}`;
      await this.ensaioService.postFile(file, url, "arquivo").toPromise();
    }, Promise.resolve());

    this.progress = 0;
    this.loadingService.dismiss();
    this.filesTratada = [];
    this.noMoreFotosTratadas = false;
    this.buscarFotosTratadas(this.data.id);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onRemoveTratada(event) {
    this.filesTratada.splice(this.filesTratada.indexOf(event), 1);
  }

  onRemoveDocumento(event) {
    this.filesDocumentos.splice(this.filesDocumentos.indexOf(event), 1);
  }

  deletePhoto(foto: EnsaioFoto) {
    const index = this.fotos.findIndex((f) => f.id === foto.id);

    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.ensaioFotoService.delete(foto).subscribe(
          (res: any) => {
            this.fotos.splice(index, 1);
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletePhotoTratada(foto: EnsaioFotoTratada) {
    const index = this.fotosTratadas.findIndex((f) => f.id === foto.id);

    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.ensaioFotoTratadaService.delete(foto).subscribe(
          (res: any) => {
            if (index >= 0) {
              this.fotosTratadas.splice(index, 1);
            }
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  changeComment(foto: EnsaioFoto) {
    this.loadingService.present("Salvando...");

    this.ensaioFotoService.changeComment(foto.id, foto.comentario).subscribe(
      (res: any) => {
        foto.isEditing = false;
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  favoritePhoto(foto: EnsaioFoto) {
    foto.favorited = !foto.favorited;
    foto.favorited ? (foto.favorita = "S") : (foto.favorita = "N");

    this.ensaioFotoService.changeFavorite(foto.id, foto.favorita).subscribe(
      (res: any) => {
        this.helper.openSnackBar("Item alterado com sucesso!");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  downloadPhoto(foto: EnsaioFoto) {
    var link = document.createElement("a");
    link.href = foto.foto;
    link.download = foto.foto;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onStatusChange(e) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Alterar status",
        description:
          "Você realmente quer excluir esse item? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Mudando status...");
        this.data.status = e;
        this.ensaioService.changeStatus(this.data).subscribe(
          (res) => {
            this.helper.openSnackBar("Status atualizado");
            this.loadingService.dismiss();
          },
          (e) => this.loadingService.dismiss()
        );
      } else {
        this.buscar(this.data.id);
      }
    });
  }
}
