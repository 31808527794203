import { Categoria } from './categoria.model'

export class Portifolio {

    id: number;
    titulo: string = '';
    descricaoSimples: string = '';
    descricao: string = '';
    data: Date = new Date();
    profissional: string = '';
    slug: string = '';
    categoria: Categoria;
    imagemPequena: string = '';
    imagemGrande: string = '';
    tipo: string = 'E';
    situacao: string = 'A';
    imagens: Imagem[];
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}

export interface Imagem {
    id: number;
    nome: string;
    imagem: string;
}