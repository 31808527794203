import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnsaioFotoEscolhida } from 'src/app/models/ensaio-foto-escolhida.model';
import { EnsaioFoto } from 'src/app/models/ensaio-foto.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class EnsaioFotoEscolhidaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, idEnsaio: number): Observable<EnsaioFotoEscolhida[]> {
    return this.api.get(`/ensaioFotoEscolhida/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idEnsaio=${idEnsaio}`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/ensaioFotoEscolhida/buscar?id=${id}`);
  }

  post(foto: EnsaioFoto): Observable<any> {
    return this.api.post('/ensaioFotoEscolhida/inserir', foto);
  }

  delete(foto: EnsaioFoto): Observable<any> {
    return this.api.post('/ensaioFotoEscolhida/deletar', foto);
  }

  changeComment(id: number, comentario: string) {
    return this.api.get(`/ensaioFotoEscolhida/alterarComentario?id=${id}&comentario=${comentario}`);
  }
}
