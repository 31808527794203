import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ensaio } from 'src/app/models/ensaio.model';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class EnsaioService {

  constructor(
    public api: ApiService,
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, idUsuario: any = '%', status: any = '%', situacao: any = '%', filtro?: string): Observable<EnsaiosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/ensaio/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idUsuario=${idUsuario}&status=${status}&situacao=${situacao}`;
    } else {
      requestUrl = `/ensaio/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idUsuario=${idUsuario}&status=${status}&situacao=${situacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/ensaio/buscar?id=${id}`);
  }

  post(ensaio: Ensaio): Observable<any> {
    return this.api.post('/ensaio/inserir', ensaio);
  }

  patch(ensaio: Ensaio): Observable<any> {
    return this.api.post('/ensaio/alterar', ensaio);
  }

  delete(ensaio: Ensaio): Observable<any> {
    return this.api.post('/ensaio/deletar', ensaio);
  }

  deleteSelected(ensaios: Ensaio[]): Observable<any> {
    return this.api.post('/ensaio/deletarLista', ensaios);
  }

  changeStatus(ensaio: Ensaio) {
    return this.api.post('/ensaio/alterarStatus', ensaio);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface EnsaiosApi {
  ensaios: Ensaio[];
  numeroPaginas: number;
}


