import { Ensaio } from 'src/app/models/ensaio.model';
import { EnsaiosApi, EnsaioService } from 'src/app/services/class/ensaio.service';
import { UsuarioService } from 'src/app/services/class/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';
// default
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { EnsaioDocumento } from 'src/app/models/ensaio-documento.model';

@Component({
  selector: 'app-ensaios-cliente',
  templateUrl: './ensaios-cliente.component.html',
  styleUrls: ['./ensaios-cliente.component.scss']
})
export class EnsaiosClienteComponent implements OnInit {

  data: Ensaio[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  idUsuario: number;
  status: any = '%';

  constructor(
    public global: GlobalService,
    public ensaioService: EnsaioService,
    public usuarioService: UsuarioService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService
  ) {
    this.idUsuario = this.auth.user.id;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.buscar();
  }

  buscar() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.ensaioService.get(-99, -99, '1', 'ASC', this.idUsuario, this.status, 'A');
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.ensaios.length;
          return data.ensaios;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  onStatusChange(e) {
    this.buscar();
  }

  solicitar(ensaio: Ensaio) {

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Solicitar ensaio',
        description: 'Você realmente quer solicitar esse ensaio? Esse processo não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Mudando status...');
        ensaio.status = 'TRATAMENTO_FOTOS';
        this.ensaioService.changeStatus(ensaio)
          .subscribe(res => {
            this.helper.openSnackBar('Ensaio solicitado');
            this.loadingService.dismiss();
          }, e => this.loadingService.dismiss());
      }
    })
  }
}
