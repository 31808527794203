import { Usuario } from './usuario.model'

export class Ensaio {

    id: number;
    nome: string = '';
    usuario: Usuario;
    data: Date = new Date();
    imagem: string = '';
    status: string = 'CRIACAO';
    dataHoraCriacao: string = null;
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }

}