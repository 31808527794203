import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { ModalImageComponent } from "src/app/components/modal-image/modal-image.component";
import { EnsaioDocumento } from "src/app/models/ensaio-documento.model";
import { EnsaioFotoEscolhida } from "src/app/models/ensaio-foto-escolhida.model";
import { EnsaioFotoTratada } from "src/app/models/ensaio-foto-tratada.model";
import { EnsaioFoto } from "src/app/models/ensaio-foto.model";
import { Ensaio } from "src/app/models/ensaio.model";
import { AuthService } from "src/app/services/auth.service";
import { EnsaioDocumentoService } from "src/app/services/class/ensaio-documento.service";
import { EnsaioFotoEscolhidaService } from "src/app/services/class/ensaio-foto-escolhida.service";
import { EnsaioFotoTratadaService } from "src/app/services/class/ensaio-foto-tratada.service";
import { EnsaioFotoService } from "src/app/services/class/ensaio-foto.service";
import { EnsaioService } from "src/app/services/class/ensaio.service";
import { HelperService } from "src/app/services/helper.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-ensaios-cliente-editar",
  templateUrl: "./ensaios-cliente-editar.component.html",
  styleUrls: ["./ensaios-cliente-editar.component.scss"],
})
export class EnsaiosClienteEditarComponent implements OnInit {
  data: Ensaio = new Ensaio();

  todo = ["Get to work", "Pick up groceries", "Go home", "Fall asleep"];

  done = ["Get up", "Brush teeth", "Take a shower", "Check e-mail", "Walk dog"];

  fotos: EnsaioFoto[] = [];
  fotosEscolhidas: EnsaioFotoEscolhida[] = [];

  fotosTratadas: EnsaioFotoTratada[] = [];

  documentos: EnsaioDocumento[] = [];

  page: number = 1;
  itemsPerPage: number = 15;
  noMore: boolean = false;

  tabIndex: number = 0;

  @HostListener("window:scroll", [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 44
    ) {
      if (this.tabIndex == 2 && !this.noMoreFotosTratadas) {
        this.loadMoreFotosTratadas();
      }

      if (this.tabIndex == 0 && !this.noMore) {
        this.loadMore();
      }

      if (this.tabIndex == 0 && !this.noMoreFotosEscolhidas) {
        this.loadMoreFotosEscolhidas();
      }
    }
  }

  constructor(
    public auth: AuthService,
    public ensaioService: EnsaioService,
    public ensaioFotoService: EnsaioFotoService,
    public ensaioFotoEscolhidaService: EnsaioFotoEscolhidaService,
    public ensaioFotoTratadaService: EnsaioFotoTratadaService,
    public ensaioDocumentoService: EnsaioDocumentoService,
    public router: Router,
    public route: ActivatedRoute,
    public helper: HelperService,
    public loadingService: LoadingService,
    public dialog: MatDialog
  ) {
    this.route.queryParams.subscribe((query) => {
      if (query.tab) {
        this.tabIndex = query.tab;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.buscar(param.id);
      this.buscarFotos(param.id);
      this.buscarFotosEscolhidas(param.id);
      this.buscarFotosTratadas(param.id);
      this.buscarDocumentos(param.id);
    });
  }

  buscar(id: number) {
    this.ensaioService
      .getById(id)
      .subscribe((res: Ensaio) => (this.data = res));
  }

  buscarFotos(idEnsaio: number) {
    this.ensaioFotoService
      .getSelect(this.page, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFoto[]) => {
        if (this.noMore) {
          return;
        }

        if (res.length < this.itemsPerPage) {
          this.noMore = true;
        }

        this.fotos = this.page == 1 ? res : this.fotos.concat(res);

        this.fotos = this.fotos.map((foto) => {
          if (foto.favorita === "S") {
            foto.favorited = true;
          } else {
            foto.favorited = false;
          }
          return foto;
        });
      });
  }

  loadMore() {
    this.page++;
    this.buscarFotos(this.data.id);
  }

  pageFotosEscolhidas: number = 1;
  noMoreFotosEscolhidas: boolean = false;
  buscarFotosEscolhidas(idEnsaio: number) {
    this.ensaioFotoEscolhidaService
      .get(this.pageFotosEscolhidas, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFotoEscolhida[]) => {
        if (this.noMoreFotosEscolhidas) {
          return;
        }
        if (res.length < this.itemsPerPage) {
          this.noMoreFotosEscolhidas = true;
        }
        this.fotosEscolhidas =
          this.pageFotosEscolhidas == 1
            ? res
            : this.fotosEscolhidas.concat(res);
      });
  }

  loadMoreFotosEscolhidas() {
    this.pageFotosEscolhidas++;
    this.buscarFotosEscolhidas(this.data.id);
  }

  pageFotosTratadas: number = 1;
  noMoreFotosTratadas: boolean = false;
  buscarFotosTratadas(idEnsaio: number) {
    this.ensaioFotoTratadaService
      .get(this.pageFotosTratadas, this.itemsPerPage, idEnsaio)
      .subscribe((res: EnsaioFotoTratada[]) => {
        if (this.noMoreFotosTratadas) {
          return;
        }
        if (res.length < this.itemsPerPage) {
          this.noMoreFotosTratadas = true;
        }
        if (res.length) {
          this.tabIndex = 2;
        }
        this.fotosTratadas =
          this.pageFotosTratadas == 1 ? res : this.fotosTratadas.concat(res);
      });
  }

  pageDocumentos: number = 1;
  noMoreDocumentos: boolean = false;
  buscarDocumentos(idEnsaio: number) {
    this.ensaioDocumentoService
      .getCliente(this.pageDocumentos, this.itemsPerPage, idEnsaio)
      .subscribe((res) => {
        if (this.noMoreDocumentos) {
          return;
        }
        if (res.length < this.itemsPerPage) {
          this.noMoreDocumentos = true;
        }
        this.documentos =
          this.pageDocumentos == 1 ? res : this.documentos.concat(res);
      });
  }

  downloadDocumento(documento) {
    window.open(documento.documento, "_target");
  }

  loadMoreFotosTratadas() {
    this.pageFotosTratadas++;
    this.buscarFotosTratadas(this.data.id);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (event.container.id === "fotosEscolhidas") {
        const foto = this.fotosEscolhidas[event.currentIndex];
        this.inserirFoto(foto);
      } else {
        const foto = this.fotos[event.currentIndex];
        this.deletarFoto(foto);
      }
    }
  }

  inserirFoto(foto: any) {
    this.loadingService.present("Inserindo...");
    this.ensaioFotoEscolhidaService.post(foto).subscribe(
      (res) => {
        this.helper.openSnackBar("Foto inserida com sucesso.");
        this.loadingService.dismiss();
        this.pageFotosEscolhidas = 1;
        this.fotosEscolhidas = [];
        this.noMoreFotosEscolhidas = false;
        this.buscarFotosEscolhidas(this.data.id);
      },
      (e) => this.loadingService.dismiss()
    );
  }

  deletarFoto(foto: any) {
    this.loadingService.present("Removendo...");
    this.ensaioFotoEscolhidaService.delete(foto).subscribe(
      (res) => {
        this.helper.openSnackBar("Foto removida com sucesso.");
        this.loadingService.dismiss();
        this.noMore = false;
        this.page = 1;
        this.fotos = [];
        this.buscarFotos(this.data.id);
      },
      (e) => this.loadingService.dismiss()
    );
  }

  changeComment(foto: EnsaioFotoEscolhida) {
    if (!foto.comentario) {
      this.helper.openSnackBar("Preencha o comentário para salvar.");
      return;
    }

    this.loadingService.present("Salvando...");

    this.ensaioFotoEscolhidaService
      .changeComment(foto.id, foto.comentario)
      .subscribe(
        (res: any) => {
          this.helper.openSnackBar("Comentário atualizado com sucesso!");
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  favoritePhoto(foto: EnsaioFoto) {
    foto.favorited = !foto.favorited;
    foto.favorited ? (foto.favorita = "S") : (foto.favorita = "N");

    this.ensaioFotoService.changeFavorite(foto.id, foto.favorita).subscribe(
      (res: any) => {
        this.helper.openSnackBar("Item alterado com sucesso!");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  onStatusChange(e) {}

  solicitar() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Solicitar ensaio",
        description:
          "Você realmente quer solicitar esse ensaio? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Mudando status...");
        this.data.status = "TRATAMENTO_FOTOS";
        this.ensaioService.changeStatus(this.data).subscribe(
          (res) => {
            this.router.navigate(["/c/ensaios"]).then(() => {
              this.helper.openSnackBar("Ensaio solicitado");
              this.loadingService.dismiss();
            });
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  zoom(imagem: string) {
    const dialogRef = this.dialog.open(ModalImageComponent, {
      width: "720px",
      data: {
        image: imagem,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  downloadAll() {
    this.loadingService.present("Gerando zip...");
    this.ensaioFotoTratadaService.download(this.data.id).subscribe(
      (res) => {
        window.open(res, "_blank");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  downloadPhoto(foto: EnsaioFoto) {
    window.open(foto.foto, "_blank");
  }
}
