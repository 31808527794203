import { SobreService } from './../../services/class/sobre.service';
import { Sobre } from './../../models/sobre.model';
// Default
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  data: Sobre = new Sobre();

  buscarSubscription: Subscription;

  constructor(
    public sobreService: SobreService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
    this.buscarData();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscarData() {
    this.buscarSubscription = this.sobreService.get()
      .subscribe((res: Sobre) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando...');

    this.sobreService.patch(this.data)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Item alterado com sucesso.');
        this.router.navigate(['/']);
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
