import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Imagem, Portifolio } from 'src/app/models/portifolio.model';

@Injectable({
  providedIn: 'root'
})
export class PortifolioService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, tipo: string, filtro?: string): Observable<PortifoliosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/portifolio/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&tipo=${tipo}`;
    } else {
      requestUrl = `/portifolio/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&tipo=${tipo}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/portifolio/buscar?id=${id}`);
  }

  post(portifolio: Portifolio): Observable<any> {
    return this.api.post('/portifolio/inserir', portifolio);
  }

  patch(portifolio: Portifolio): Observable<any> {
    return this.api.post('/portifolio/alterar', portifolio);
  }

  delete(portifolio: Portifolio): Observable<any> {
    return this.api.post('/portifolio/deletar', portifolio);
  }

  deleteSelected(portifolios: Portifolio[]): Observable<any> {
    return this.api.post('/portifolio/deletarLista', portifolios);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  deleteImagemGaleria(idImagem: number) {
    return this.api.get(`/portifolioImagem/deletar?idImagem=${idImagem}`);
  }

  order(imagens: Imagem[]) {
    return this.api.post(`/portifolioImagem/ordenar`, imagens);
  }
}

export interface PortifoliosApi {
  portifolios: Portifolio[];
  numeroPaginas: number;
}
