import { Portifolio, Imagem } from 'src/app/models/portifolio.model';
import { PortifolioService } from 'src/app/services/class/portifolio.service';
import { Categoria } from 'src/app/models/categoria.model';
import { CategoriaService, CategoriasApi } from 'src/app/services/class/categoria.service';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-portifolios-editar',
  templateUrl: './portifolios-editar.component.html',
  styleUrls: ['./portifolios-editar.component.scss']
})
export class PortifoliosEditarComponent implements OnInit {

  isOrdering: boolean = false;

  data: Portifolio = new Portifolio();
  buscarSubscription: Subscription;

  fileImagemPequena: File;
  fileImagemGrande: File;
  files: File[] = [];
  progress: any;

  categorias: Categoria[] = [];
  buscarCategoriasSubscription: Subscription;
  searchCategorias: string = '';

  constructor(
    public helper: HelperService,
    public portifolioService: PortifolioService,
    public categoriaService: CategoriaService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarData(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
    this.buscarCategoriasSubscription.unsubscribe();
  }

  buscarData(id: number) {
    this.buscarSubscription = this.portifolioService.getById(id)
      .subscribe((res: Portifolio) => {
        this.data = res;
        this.data.imagens = this.orderList(res.imagens);
        this.buscarCategorias();
      });
  }

  buscarCategorias() {
    this.buscarCategoriasSubscription = this.categoriaService.get(-99, -99, '1', 'ASC', this.data.tipo)
      .subscribe((res: CategoriasApi) => this.categorias = res.categorias, e => console.log(e));
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando...');
    this.data.slug = this.helper.convertToSlug(this.data.titulo);

    this.portifolioService.patch(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.fileImagemPequena, `/portifolio/imagemPequena?id=${this.data.id}`);
        this.submitImages(this.fileImagemGrande, `/portifolio/imagemGrande?id=${this.data.id}`);
        this.router.navigate(['/portfolios'], {
          queryParams: { tipo: this.data.tipo }
        }).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  onTipoChange(event) {
    this.buscarCategorias();
  }

  @ViewChild('fileImagemPequenaInput', { static: true }) fileImagemPequenaInput: ElementRef;
  onFileImagemPequenaSelected(files) {
    this.fileImagemPequena = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPequena, '/portifolio/imagemPequena?id=-99').then((res: any) => {
      this.data.imagemPequena = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPequenaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemGrandeInput', { static: true }) fileImagemGrandeInput: ElementRef;
  onFileImagemGrandeSelected(files) {
    this.fileImagemGrande = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemGrande, '/portifolio/imagemGrande?id=-99').then((res: any) => {
      this.data.imagemGrande = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemGrandeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.portifolioService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImagemPequena() {
    this.data.imagemPequena = '';
  }

  removeImagemGrande() {
    this.data.imagemGrande = '';
  }

  onSelect(event) {

    this.files.push(...event.addedFiles);
    let count = event.addedFiles.length;

    this.loadingService.present('Inserindo');

    event.addedFiles.forEach(file => {
      const url = `/portifolioImagem/carregar?idPortifolio=${this.data.id}`;
      this.portifolioService.postFile(file, url, 'arquivo')
        .subscribe((e: HttpProgressEvent | any) => {
          if (e.type === 4) {
            count--;
            if (count == 0) {
              this.progress = 0;
              this.loadingService.dismiss();
              this.files = [];
              this.buscarData(this.data.id);
            }
          } else {
            this.progress = Math.round((e.loaded / e.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        })
    });
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  deletarImagem(imagem: Imagem, index: number) {
    this.loadingService.present('Deletando imagem...');
    this.portifolioService.deleteImagemGaleria(imagem.id).subscribe(res => {
      this.data.imagens.splice(index, 1);
      this.buscarData(this.data.id)
      this.loadingService.dismiss()
    }, e => this.loadingService.dismiss())
  }

  orderList(list: any[]): any[] {
    const listOrdered = list.map((p, i) => {
      p.ordem = i;
      return p;
    });
    return listOrdered;
  }

  drop(event: CdkDragDrop<string[]>) {
    this.loadingService.present('Ordenando...');
    moveItemInArray(this.data.imagens, event.previousIndex, event.currentIndex);
    const imagens = this.orderList(this.data.imagens)
    this.portifolioService.order(imagens).subscribe(res => {
      this.helper.openSnackBar('Item ordenado');
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }
}
